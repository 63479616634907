<template>
  <div class="wrap">
    <div class="title">注意事项</div>
    <div class="def" style="margin-top:10px">1. 求购有效期为7天。在完成交易前，您可以随时取消求购，或者7天后系统将自动取消。</div>
    <div class="def">2. 求购取消后，您支付的金额将原路退回。若您通过蒸汽币支付，将退回到您的钱包中。</div>
    <div class="def">3. 交易完成后，金额将转化为蒸汽币给卖家，无法撤销。卡券将自动拆封，无法二次挂售。</div>
    <div class="def">4. 若您的交易过程中遭遇问题，请通过「我的-联系客服」获取支持。</div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class AttentionItemDemand extends Vue {}
</script>

<style lang="scss" scoped>
.wrap {
  height: 160px;
  background: white;
  padding: 16px;
  .title {
    color: black;
    font-weight: bold;
    font-size: 13px;
  }
  .def {
    color: #969696;
    font-size: 12px;
  }
}
</style>
