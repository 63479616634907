






















































import { Component, Vue, Watch, Prop, Ref } from "vue-property-decorator";
import PayPop from "@/views/market-pay/components/PayPop.vue";
import { Toast, Popup, Loading } from "vant";
import WalletPaymentSelector from "@/components/WalletPaymentSelector.vue";
import SearchApi from "@/api/bazaar.api";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import { systemPay } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
@Component({
  components: {
    WalletPaymentSelector,
    PayPop,
    Popup,
    Loading,
  },
})
export default class BottomLayout extends Vue {
  @Ref("WalletPaymentSelector")
  private readonly paymentSelectorRef!: WalletPaymentSelector;

  globalState = getModule(GlobalStateStore);

  show = false;
  styleState = true;
  popState = false;
  radio = "1";
  fakeMoney = 0;
  number = 0;
  @Prop() AutoShow: any;
  @Prop() price: any;
  @Prop() balance_money: any;
  @Prop() sale_min_price: any;
  @Prop() detail_id: any;

  actPrice = 0;
  timertime = 0;

  @Watch("show")
  watchShow(e: any) {
  }
  @Watch("AutoShow")
  watchAutoShow(e: any) {
    this.show = this.AutoShow;
  }
  @Watch("price")
  watchPrice(e: any) {
    this.actPrice = this.price;
  }
  created() {}
  test() {
    this.styleState = true;
  }
  cheep(e: number) {
    this.fakeMoney = e;
  }
  checkRadio(radio: string) {
    this.radio = radio;
  }
  showPop(e: any) {
    this.show = false;
    this.popState = true;
  }
  combine(e: any) {
    this.styleState = e;
  }
  close() {
    this.styleState = true;
    this.show = false;
  }
  async pay() {
    if (this.price == 0 && this.show == false) {
      Toast.fail("求购价格不可为0");
      return;
    }
    // this.styleState = true
    // this.show = true
    this.paymentSelectorRef.show(
      this.price,
      async (channel, deduction_wallet) => {
        const orderRow = await SearchApi.payMoney(this.detail_id, {
          money: Math.ceil(this.price * 1000) / 10,
          pay_channel: channel,
          deduction_wallet,
          openid: channel === 3 ? this.globalState.openId || "" : "",
          appid: InteractionLib.getAppid(this.globalState.packageName),
        }).catch((err) => {
          Toast.clear();
          Toast.fail(err.message);
          throw err;
        });
         if (channel !== 4) {
          const result = await systemPay(channel, orderRow);
          if (!result && ![1, 2].includes(channel)) {
            Toast("支付取消");
            return;
          }
          if (!result && [1, 2].includes(channel)) return;
        }
       
        this.popState = true;
        let timer = setInterval(async () => {
          const res = await SearchApi.getMoneyState(orderRow.order_no).catch(
            (err) => {
              clearTimeout(timer);
              throw err;
            }
          );
          if (res.status != 0) {
            clearInterval(timer);
            this.popState = false;
            await this.$router.push({
              name: "demand",
              query: { active: res.status == 1 ? "0" : "1" },
            });
          }
          this.timertime++;
          if (this.timertime > 3) {
            this.timertime = 0;
            clearInterval(timer);
            this.popState = false;
            await this.$router.push({
              name: "demand",
              query: {
                active: "0",
              },
            });
            return;
          }
        }, 1000);
      }
    );
  }
}
