<template>
  <div class="wrap" v-if="items">
    <div class="product">
      <div class="product-img">
        <img :src="items.cover" alt="" style="object-fit: cover" />
      </div>
      <div class="product-desc">
        <div
          style="
            font-weight: bold;
            font-size: 18px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          "
        >
          {{ items.title }}
        </div>
        <div class="product-price">
          <div class="flex-colume">
            <div class="des">在售最低</div>
            <div class="price">
              {{ sale_min_price }}
            </div>
          </div>
          <div style="color: #dddddd">｜</div>
          <div class="flex-colume">
            <div class="des">求购最高</div>
            <div class="price">
              {{ entrust_max_price }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="myprice">
      <div>我的求购价 ¥</div>
      <Field
        ref="inp"
        plain
        readonly
        v-model="price"
        type="number"
        placeholder="请输入求购单价"
        @click="focusa"
        @touchstart.native.stop="shower = true"
      />
      <div
        class="flex"
        v-show="price"
        style="position: absolute; right: 30px"
        @click="focusa"
      >
        <img
          src="https://cdn.vgn.cn/static/market/change.png"
          alt=""
        />
        <div style="margin-left: 2px">修改</div>
      </div>
    </div>
    <AttentionItem style="margin-top: 10px" />

    <bottom-layout
      @click="ifclick"
      class="button"
      :detail_id="detail_id"
      :price="price"
      :sale_min_price="items.sale_min_price"
      :balance_money="money.balance_money"
      :AutoShow="AutoShow"
    ></bottom-layout>
    <NumberKeyboard
      :show="shower"
      v-model="price"
      @blur="shower = false"
      @input="onInput"
      @delete="onDelete"
      extra-key="."
      close-button-text="完成"
    />
  </div>
</template>

<script>
import { Component, Vue, Watch,Ref } from "vue-property-decorator";
import AttentionItem from "@/components/AttentionItemDemand.vue";
import BottomLayout from "@/views/market-pay/components/BottomLayout.vue";
import { Field ,NumberKeyboard} from "vant";
import SearchApi from "@/api/bazaar.api";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: {
    AttentionItem,
    BottomLayout,
    Field,
    NumberKeyboard
  },
})
export default class Marketpay extends Vue {
  @Ref("inp") inpRef
  price = '';
  items = {};
  playerList = [];
  moneyMaxLeng = 8;
  money = {};
  detail_id = "";
  AutoShow = false;
  show = false;
  shower = false
  entrust_max_price = ''
  sale_min_price = ''
  @Watch("price")
  watchPrice(e) {
    let val = String(e)
    let res = val.match(/^[0-9]{0,6}(\.[0-9]{0,2})?$/g)
    if(res){
          val = val.replace(/[^\d.]/g,"") //清除非 数字和小数点的字符
          val = val.replace(/\.{2,}/g,".") //清除第二个小数点
          val = val.replace(/^\./g,""); //验证第一个字符是数字而不是字符
          val = val.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
          val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); //保留两位小数
          val = val.indexOf(".") > 0? val.split(".")[0].substring(0, 6) + "." + val.split(".")[1]: val.substring(0, 6); //限制只能输入7位正整数
          this.price = val;
    }else{
      this.price = this.price.substring(0,this.price.length-1)
    }
  }

  async created() {
    this.money = await SearchApi.getMoney();
    if (InteractionLib.isApp()) {
      this.detail_id = this.$route.params.id
      let right = this.$route.query.right
      this.items = await SearchApi.getProductDetail(this.detail_id);
      if (right == 'true') {
      setTimeout(() => {
        this.AutoShow = true;
      }, 1000);
      this.price = this.items.sale_min_price;
      }
    }else{
      this.detail_id = this.$route.params.id
      this.items = await SearchApi.getProductDetail(this.detail_id);
      if (this.$route.query.right) {
        setTimeout(() => {
          this.AutoShow = true;
        }, 1000);
        this.price = this.items.sale_min_price;
      }
    }
    this.entrust_max_price = (this.items.entrust_max_price == "0.00" ? '-':`¥${this.items.entrust_max_price}`)
    this.sale_min_price = this.items.sale_min_price == '0.00'? '-':`¥${this.items.sale_min_price}`
  }
  focusa() {
    this.inpRef.focus()
    this.shower = true
  }
  onInput(value){
    this.price = String(this.price)+ String(value)
  }
  onDelete(){
    this.price = String(this.price).slice(0,String(this.price.length-1))
  }
  ifclick() {
    return;
  }
  clearNoNum(obj) {

  }
}
</script>

<style lang="scss" scoped>
.van-cell::after {
  border: none;
}
.wrap {
  padding-bottom: 80px;
}
.myprice {
  position: relative;
  display: flex;
  background: white;
  padding: 12px 20px 0 20px;
  padding-bottom: 16px;
  font-size: 14px;
  height: 30px;
  align-items: center;
  .van-cell {
    width: 230px !important;
    font-size: 14px;
    font-weight: 800;
  }
  .flex {
    display: flex;
    align-items: center;
    color: #878e99;
    font-size: 12px;
    img {
      width: 10px;
      height: 10px;
    }
  }
}
.button {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  height: 80px;
}

.product {
  border-bottom: 1px solid #f2f2f2;
  .product-img {
    width: 100%;
    height: 211px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .product-desc {
    padding: 12px 16px 0 16px;
    background: white;
    height: 155px;
  }
  .product-price {
    height: 73px;
    background: #f8f8f8;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 62px;
    box-sizing: border-box;
    .flex-colume {
      display: flex;
      align-items: center;
      flex-direction: column;
      .des {
        font-size: 12px;
        color: #878e99;
      }
      .price {
        color: #f83737;
        font-size: 16px;
        font-weight: bold;
        margin-top: 4px;
      }
    }
  }
}
</style>
