<template>
  <div class="bgwrap">
    <div class="content">
      <div class="title">请选择支付方式</div>
      <img
        @click="close"
        class="close"
        src="https://cdn.vgn.cn/static/Official-website/close.png"
        alt=""
      />

      <div class="list">
        <RadioGroup v-model="radio">
          <div class="flex" style="margin-top: 20px">
            <img
              src="https://cdn.vgn.cn/static/Official-website/weixin.png"
              alt=""
            />
            <div style="flex: 1; margin-left: 20px">微信支付</div>
            <Radio name="1" checked-color="#ee0a24"></Radio>
          </div>
          <div class="flex" style="margin-top: 30px" v-if="deductionState">
            <img
              src="https://cdn.vgn.cn/static/Official-website/wallet.png"
              alt=""
            />
            <div style="flex: 1; margin-left: 20px">
              <span>蒸汽币</span>
              <span
                ><img
                  src="https://cdn.vgn.cn/static/Official-website/coins.png"
                  alt=""
                  style="margin-left: 10px; width: 15px; height: 15px"
              /></span>
              <span style="font-size: 16px"> {{ balance_money }}</span>
            </div>
            <Radio name="2" checked-color="#ee0a24"></Radio>
          </div>
          <div class="flex" style="margin-top: 30px" v-else>
            <img
              src="https://cdn.vgn.cn/static/Official-website/wallet.png"
              alt=""
            />
            <div style="flex: 1; margin-left: 20px">
              <span>蒸汽币:</span>
              <span
                ><img
                  src="https://cdn.vgn.cn/static/Official-website/coins.png"
                  alt=""
                  style="margin-left: 10px; width: 15px; height: 15px"
              /></span>
              <span style="font-size: 16px"> {{ balance_money }}</span>
            </div>
            <div class="flex">
              <span style="font-size: 14px; color: #969696; margin-right: 10px"
                >使用蒸汽币抵扣</span
              >
              <DiySwitch
                @change="changeHandler"
                class="diyswitch"
                v-model="checked"
                inactive-color="white"
                @click="combine"
              />
            </div>
          </div>
        </RadioGroup>
      </div>
      <div
        style="
          font-size: 11px;
          color: #969696;
          padding: 10px 16px;
          position: fixed;
          bottom: 80px;
          left: 0;
          width: 100%;
        "
      >
        <div style="font-weight: 800">消费提醒:</div>
        <div style="margin-top: 10px">
          1. 购买游戏需年满18周岁，未成年人禁止购买。
        </div>
        <div>2. 游戏、超值补给箱为特殊商品，售出后不支持无理由退款。</div>
        <div style="margin-top: 15px">
          购买即视为同意《消费提醒》 与<a
            target="_blank"
            @click="openProtocolUser"
            style="color: #1677ff"
            >{{ globalState.packageName | appfitler }}</a
          >
        </div>
      </div>

      <div
        style="
          background: white;
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 80px;
        "
      >
        <div class="bottom-flex" v-if="styleState">
          <div class="title" style="font-weight: 800">应付</div>
          <div class="price" style="font-weight: 800">
            ¥&nbsp;{{ price ? price : "-" }}
          </div>
          <div class="pay" @click="ddddd">
            <div class="buy-now">支付</div>
          </div>
        </div>
        <div class="bottom-flex" v-else>
          <div class="flex-column">
            <div class="flex-row">
              <div class="title" style="font-weight: 600">应付</div>
              <div style="font-weight: 600; color: red">¥</div>
              <div class="price" style="font-weight: 600">
                &nbsp;{{ fakeMoney }}
              </div>
            </div>
            <div class="wallet">蒸汽币已抵扣{{ balance_money }}</div>
          </div>
          <div class="pay" @click="ddddd">
            <div class="buy-now">支付</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Radio, RadioGroup, Icon, Switch, Toast } from "vant";
import SearchApi from "@/api/bazaar.api";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import { wechatPay } from "@/utils/common.util";

export default {
  data() {
    return {
      number: "",
      radio: "1",
      checked: false,
      money: {},
      deductionState: false,
      globalState: {},
      timertime: 0,
    };
  },
  props: {
    balance_money: Number,
    sale_min_price: String,
    price: String,
    styleState: Boolean,
    actPrice: String,
    show: Boolean,
    detail_id: String,
  },
  watch: {
    radio: {
      handler: function (newVal, oldVal) {
        this.$emit("checkRadio", newVal);
      },
    },
  },
  components: {
    Radio,
    RadioGroup,
    Icon,
    DiySwitch: Switch,
  },
  created() {
    this.globalState = getModule(GlobalStateStore);
    if (Number(this.balance_money) - Number(this.price) >= 0) {
      this.deductionState = true;
    }
  },
  filters: {
    appfilter(value) {
      if (value == "com.vgn.gamepower") {
        return "《游戏动力用户协议》";
      }
      return "《超级蒸汽用户协议》";
    },
  },
  methods: {
    openProtocolUser() {
      if (InteractionLib.isApp()) {
        if (this.globalState.packageName == "com.vgn.gamepower") {
          InteractionLib.openWebView(
            "https://wap.vgn.cn/gamepower/gamepower.html"
          );
          return;
        }
        InteractionLib.openWebView("http://api.io.vgn.cn/steam_protocol.html");
        return;
      }
      location.href = "http://api.io.vgn.cn/steam_protocol.html";
    },
    async pay() {
      let appid = "";
      let paychannel = 3;
      let deduction = false;
      if (this.radio == "1") {
        paychannel = 3;
        appid = "wxebb0f826b7b56ce7";
      } else if (this.radio == "2") {
        paychannel = 4;
      }
      if (!this.styleState) {
        deduction = true;
      }
      if (this.show) {
        const result = await SearchApi.payMoney(this.detail_id, {
          money: Math.ceil(this.actPrice * 100),
          pay_channel: paychannel,
          deduction_wallet: deduction,
          openid: this.globalState.openId || "undefined",
          appid,
        }).catch((err) => {
          Toast.clear();
          Toast.fail(err.message);
          throw err;
        });
        if (result.pay_channel == 3) {
          const { packageValue, ...other } = result.wechatPay;
          const payStatus = await wechatPay({
            ...other,
            package: packageValue,
          });
          if (!payStatus) {
            Toast("取消支付");
            return;
          }
        }
        this.$emit("showPop", true);
        let timer = setInterval(async () => {
          const res = await SearchApi.getMoneyState(result.order_no);
          if (res.status != 0) {
            let active;
            if (res.status == 1) {
              active = "0";
            } else {
              active = "1";
            }
            clearInterval(timer);
            this.$emit("showPop", false);
            this.$router.push({
              name: "demand",
              query: {
                active,
              },
            });
          }
          this.timertime++;
          if (this.timertime > 5) {
            this.timertime = 0;
            clearInterval(timer);
            this.$emit("showPop", false);
            this.$router.push({
              name: "demand",
              query: {
                active: "0",
              },
            });
            return;
          }
        }, 1000);
      }
      this.show = true;
    },
    changeHandler(newVal) {
      if (this.balance_money <= 0) {
        this.checked = false;
        this.$emit("cheep", this.price);
        this.$emit("test");
        return;
      }
      let risePrice;
      if (newVal && this.checked != false) {
        risePrice =
          (Number(this.price) * 1000 - Number(this.balance_money) * 1000) /
          1000;
        this.fakeMoney = risePrice;
        this.$emit("cheep", risePrice);
      }
    },
    confirm() {
      this.$emit("confirm");
      document.documentElement.style.overflowY = "scroll";
    },
    close() {
      this.$emit("close");
    },
    think() {
      this.$emit("think");
      document.documentElement.style.overflowY = "scroll";
    },
    combine() {
      this.$emit("combine", this.checked);
    },
  },
};
</script>
<style lang="scss" scoped>
.bgwrap {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  width: 100%;
  height: 100%;
  .content {
    background: linear-gradient(180deg, #ffffff 0%, #f5f5f5 100%);
    position: relative;
    height: 583px;
    top: 35%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    .title {
      height: 60px;
      display: flex;
      justify-content: center;
      font-weight: 800;
      align-items: center;
    }
    .close {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 18px;
      left: 85%;
    }
  }
}

.list {
  padding: 10px 16px;
  height: 123px;
  .tips {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 22px;
      height: 22px;
    }
  }
}

.van-switch--on {
  font-size: 23px;
  ::v-deep {
    .van-switch__node {
      background: #ff3b30 !important;
      left: 60% !important;
    }
  }
}
.diyswitch {
  background: white;
  font-size: 20px;
  ::v-deep {
    .van-switch__node {
      background: #d8d8d8;
      width: 12px;
      height: 12px;
      transform: translateY(-50%);
      left: 10%;
      top: 50%;
    }
  }
}
.bottom-flex {
  display: flex;
  z-index: 1000;
  justify-content: space-between;
  background: white;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  .flex-column {
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    .wallet {
      font-size: 13px;
      color: #969696;
    }
    .flex-row {
      display: flex;
      align-items: center;
      font-size: 14px;
      height: 40px;
      .price {
        font-size: 16px;
        margin-left: 0px;
      }
    }
  }
  .price {
    font-size: 20px;
    color: red;
    flex: 1;
    margin-left: 10px;
  }
  .pay {
    width: 140.5px;
    height: 40px;
    background: linear-gradient(270deg, #ff2626 0%, #ff6e4e 100%);
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
  }
}
</style>
